<template>
  <ValidationObserver ref="fleetUploadProcessing">
    <div class="card card-custom">
      <div class="card-body container">

        <b-alert v-if="errorAlert" variant="warning" show>
          <div class="text-dark" v-html="errorAlert"></div>
        </b-alert>

        <div v-if="formData">

          <b-alert v-if="isWizardReadOnly" variant="secondary" show>
            <div class="text-dark">This form is already accepted.</div>
          </b-alert>

          <b-card border-variant="primary" class="mb-10 mt-3" body-class="px-7 py-5">
            <h4 class="text-center font-weight-bold">Fleet Upload Effective Period :
              <b-badge variant="primary">
                {{ formData.effectiveStartDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                to
                {{ formData.effectiveEndDate | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
              </b-badge>
            </h4>
          </b-card>

          <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-7 py-5">

            <div class="d-flex justify-content-between">
              <h3 class="font-weight-bold text-dark">
                CLIENT'S RENTAL LOCATIONS
              </h3>
            </div>

            <div class="form-group">
              <div v-if="Array.isArray(newBranches) && newBranches.length > 0" class="mt-5">
              <span class="text-primary">
                <h5>Newly Added Branches: {{ newBranches.length }}</h5>
              </span>
                <branches-list :branches="newBranches"></branches-list>
              </div>
            </div>

            <div class="form-group">
              <div v-if="Array.isArray(existingBranches) && existingBranches.length > 0" class="mt-5">
              <span class="text-primary">
                <h5>Existing/Updated Branches: {{existingBranches.length}}</h5>
              </span>
                <branches-list :branches="existingBranches"></branches-list>
              </div>
            </div>

            <div class="form-group">
              <div v-if="Array.isArray(deletedBranches) && deletedBranches.length > 0" class="mt-5">
              <span class="text-danger">
                <h5>Deleted Branches: {{deletedBranches.length}}</h5>
              </span>
                <branches-list :branches="deletedBranches"></branches-list>
              </div>
            </div>
          </b-card>

          <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-7 py-5">

            <div class="d-flex justify-content-between">
              <h3 class="font-weight-bold text-dark">
                CLIENT'S FLEET DECLARATION
              </h3>
              <a :href="formData.formFleetFileLink" target="_blank" class="btn btn-primary float-right">View Client's Fleet Declaration</a>
            </div>

            <div class="form-group">
              <div v-if="Array.isArray(newVehicles) && newVehicles.length > 0" class="mt-5">
              <span class="text-primary">
                <h5>Newly Added Vehicles: {{ newVehicles.length }}</h5>
              </span>
              <vehicles-list :vehicles="newVehicles" :branch-codes="branchCodes" :is-read-only="true"></vehicles-list>
              </div>
            </div>

            <div class="form-group">
              <div v-if="Array.isArray(existingVehicles) && existingVehicles.length > 0" class="mt-5">
              <span class="text-primary">
                <h5>Existing Vehicles: {{existingVehicles.length}}</h5>
              </span>
              <vehicles-list :vehicles="existingVehicles" :branch-codes="branchCodes" :is-read-only="true"></vehicles-list>
              </div>
            </div>

            <div class="form-group">
              <div v-if="Array.isArray(deletedVehicles) && deletedVehicles.length > 0" class="mt-5">
              <span class="text-danger">
                <h5>Deleted Vehicles: {{deletedVehicles.length}}</h5>
              </span>
                <vehicles-list :vehicles="deletedVehicles" :branch-codes="branchCodes" :is-read-only="true"></vehicles-list>
              </div>
            </div>

            <div class="d-flex justify-content-center" v-if="!isWizardReadOnly">
              <div class="mr-2">
                <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        @click.prevent="confirmFormAcceptance('CLIENT')">
                  Accept Client's Fleet Declaration
                </button>
              </div>
            </div>

          </b-card>

          <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-7 py-5">

            <div class="d-flex justify-content-between">
              <h3 class="font-weight-bold text-dark mb-3">
                UNDERWRITER'S FLEET DECLARATION (Corrected Fleet)
              </h3>
              <a v-if="formData.uwFleetFileLink" :href="formData.uwFleetFileLink" target="_blank" class="btn btn-primary float-right">View Underwriter's Fleet Declaration</a>
            </div>

            <div v-if="!isWizardReadOnly && csvboxReady">
              Click the 'Upload Revised Fleet' button below. Then follow the instructions on the screen and complete the process.<br/>
              <span class="font-weight-bold">Note: If you upload a new fleet multiple times, only the latest fleet declaration is considered valid.</span>

              <fleet-upload button-title="Upload Revised Fleet" :branch-codes="branchCodes" :unique-id="formData.formId"
                            :csvbox-key="formData.cblkuw" @fleetUploadCompleted="fleetUploadCompleted"/>
            </div>

            <div v-if="uwFleetUploadExists">
              <div class="form-group">
                <div v-if="Array.isArray(addedVehiclesUw) && addedVehiclesUw.length > 0" class="mt-5">
              <span class="text-primary">
                <h5>Newly Added Vehicles: {{ addedVehiclesUw.length }}</h5>
              </span>
                  <vehicles-list :vehicles="addedVehiclesUw" :branch-codes="branchCodes" :is-read-only="true"></vehicles-list>
                </div>
              </div>

              <div class="form-group">
                <div v-if="Array.isArray(existingVehiclesUw) && existingVehiclesUw.length > 0" class="mt-5">
              <span class="text-primary">
                <h5>Existing Vehicles: {{ existingVehiclesUw.length }}</h5>
              </span>
                  <vehicles-list :vehicles="existingVehiclesUw" :branch-codes="branchCodes" :is-read-only="true"></vehicles-list>
                </div>
              </div>

              <div class="form-group">
                <div v-if="Array.isArray(deletedVehiclesUw) && deletedVehiclesUw.length > 0" class="mt-5">
                  <span class="text-danger">
                    <h5>Deleted Vehicles: {{ deletedVehiclesUw.length }}</h5>
                  </span>
                  <vehicles-list :vehicles="deletedVehiclesUw" :branch-codes="branchCodes" :is-read-only="true"></vehicles-list>
                </div>
              </div>

              <div class="d-flex justify-content-center" v-if="!isWizardReadOnly">
                <div class="mr-2">
                  <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                          @click.prevent="confirmFormAcceptance('UW')">
                    Accept Underwriter's Fleet Declaration
                  </button>
                </div>
              </div>

            </div>
          </b-card>

          <b-modal ref="acceptFormModal" title="Accept Form" size="md" no-close-on-backdrop hide-header-close @ok="acceptForm">
            <div class="form-group">
              Accepting <strong>{{ (fleetBeingAccepted === 'UW') ? "Underwriter's Fleet Declaration" : "Client's Fleet Declaration" }}</strong>
              will update policy
              <strong>{{ formData.policyNumber }}</strong> with the new fleet details.<br/><br/>
              Are you sure you want to continue?
            </div>
          </b-modal>

          <b-alert v-if="isWizardReadOnly" variant="secondary" show>
            <div class="text-dark">This form is already accepted.</div>
          </b-alert>

        </div>

      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import _ from "lodash";
import KTUtil from "@/assets/js/components/util";
import VehiclesList from "@/view/pages/wizard/components/VehiclesList.vue";
import BranchesList from "@/view/pages/wizard/fleet-upload/BranchesList";
import Swal from "sweetalert2";
import BrandService from "@/core/services/brand.service";
import FleetUpload from "@/view/pages/wizard/components/FleetUpload.vue";

export default {
  name: "FleetUploadProcessing",

  components: {FleetUpload, VehiclesList, BranchesList },

  data() {
    return {
      serverUrl: null,
      formId: null,
      userId: null,
      formData: null,
      fleetBeingAccepted: null,
      errorAlert: null,
      csvboxReady: false
    };
  },

  mounted() {
    this.startPageLoading();

    // Extract information from URL params
    const queryParams = this.$route.query;
    if (!queryParams.formId || !queryParams.userId || !queryParams.token) {
      this.handleError("Unauthorised access!");
      return;
    }

    // Extract data from URL params
    this.formId = queryParams.formId;
    this.userId = queryParams.userId;

    // Extract token from URL params and set it in the session
    this.$apiService.saveTokenAndSetHeader(queryParams.token);

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV === 'development') {
      this.serverUrl = `http://localhost:3000/services`;
    }

    // Get form data.
    this.getFormDataAndInitialiseUI();
  },

  computed: {
    isWizardReadOnly: function () {
      if(this.formData) {
        return this.formData.formStatus !== "Active";
      }
      return true;
    },

    uwFleetUploadExists: function() {
      if(this.formData) {
        return Array.isArray(this.formData.uwVehicles) && this.formData.uwVehicles.length > 0;
      }
      return false;
    },

    newVehicles: function () {
      if(this.formData) {
        const formVehicles = this.formData.formVehicles || [];
        const crmVehicles = this.formData.crmVehicles || [];

        const addedVehicles = _.filter(formVehicles, formVeh => {
          return !_.some(crmVehicles, polVeh => {
            return polVeh.K_RegoNo === formVeh.K_RegoNo
                && polVeh.K_State === formVeh.K_State;
          });
        });
        return addedVehicles;
      }
      return null;
    },

    deletedVehicles: function () {
      if(this.formData) {
        const formVehicles = this.formData.formVehicles || [];
        const crmVehicles = this.formData.crmVehicles || [];

        const deletedVehicles = _.filter(crmVehicles, polVeh => {
          return !_.some(formVehicles, formVeh => {
            return polVeh.K_RegoNo === formVeh.K_RegoNo
                && polVeh.K_State === formVeh.K_State;
          });
        });
        return deletedVehicles;
      }
      return null;
    },

    existingVehicles: function () {
      if(this.formData) {
        const formVehicles = this.formData.formVehicles || [];
        const crmVehicles = this.formData.crmVehicles || [];

        const existingVehicles = _.filter(formVehicles, formVeh => {
          return _.some(crmVehicles, polVeh => {
            return polVeh.K_RegoNo === formVeh.K_RegoNo
                && polVeh.K_State === formVeh.K_State;
          });
        });

        return existingVehicles;
      }
      return null;
    },

    addedVehiclesUw: function () {
      if(this.formData) {
        const formVehicles = this.formData.uwVehicles || [];
        const crmVehicles = this.formData.crmVehicles || [];

        const addedVehicles = _.filter(formVehicles, formVeh => {
          return !_.some(crmVehicles, polVeh => {
            return polVeh.K_RegoNo === formVeh.K_RegoNo
                && polVeh.K_State === formVeh.K_State;
          });
        });
        return addedVehicles;
      }
      return null;
    },

    deletedVehiclesUw: function () {
      if(this.formData) {
        const formVehicles = this.formData.uwVehicles || [];
        const crmVehicles = this.formData.crmVehicles || [];

        const deletedVehicles = _.filter(crmVehicles, polVeh => {
          return !_.some(formVehicles, formVeh => {
            return polVeh.K_RegoNo === formVeh.K_RegoNo
                && polVeh.K_State === formVeh.K_State;
          });
        });
        return deletedVehicles;
      }
      return null;
    },

    existingVehiclesUw: function () {
      if(this.formData) {
        const formVehicles = this.formData.uwVehicles || [];
        const crmVehicles = this.formData.crmVehicles || [];

        const existingVehicles = _.filter(formVehicles, formVeh => {
          return _.some(crmVehicles, polVeh => {
            return polVeh.K_RegoNo === formVeh.K_RegoNo
                && polVeh.K_State === formVeh.K_State;
          });
        });

        return existingVehicles;
      }
      return null;
    },

    newBranches: function () {
      if(this.formData) {
        const formBranches = this.formData.formBranches || [];
        const crmBranches = this.formData.crmBranches || [];

        const newBranches = _.filter(formBranches, formBr => {
          return !_.some(crmBranches, crmBr => {
            return crmBr.K_BranchCode === formBr.K_BranchCode;
          });
        });
        return newBranches;
      }
      return null;
    },

    deletedBranches: function () {
      if(this.formData) {
        const formBr = this.formData.formBranches || [];
        const crmBranches = this.formData.crmBranches || [];

        const deletedBranches = _.filter(crmBranches, crmBr => {
          return !_.some(formBr, formVeh => {
            return crmBr.K_BranchCode === formVeh.K_BranchCode;
          });
        });
        return deletedBranches;
      }
      return null;
    },

    existingBranches: function () {
      if(this.formData) {
        const formBranches = this.formData.formBranches || [];
        const crmBranches = this.formData.crmBranches || [];

        const existingBranches = _.filter(formBranches, formBr => {
          return _.some(crmBranches, crmBr => {
            return crmBr.K_BranchCode === formBr.K_BranchCode;
          });
        });

        return existingBranches;
      }
      return null;
    },

    branchCodes: function() {
      let branchCodes = [];
      if(this.formData && Array.isArray(this.formData.formBranches)) {
        branchCodes = _.map(this.formData.formBranches, 'K_BranchCode');
      }
      for (let [i, branchCode] of branchCodes.entries()) {
        if(branchCode) {
          branchCodes[i] = branchCode.toUpperCase();
        }
      }
      return branchCodes;
    }
  },

  methods: {

    getFormDataAndInitialiseUI: function () {
      this.$apiService.get(`${this.serverUrl}/fleet-upload-processing/form/${this.formId}`)
          .then(({ data }) => {
            this.formData = data.result;

            // Set UI Brand
            BrandService.setUIBrand(this.formData.brand);

            // Set wizard title and subtitle
            this.$store.commit("setWizardTitle", `Fleet Upload : ${this.formData.policyNumber}`);
            this.$store.commit("setWizardSubtitle", this.formData.clientName);

            this.$nextTick(() => {
              this.csvboxReady = true;
              this.stopPageLoading();
            });

          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    fleetUploadCompleted: function (result, data) {
      if (result && data) {
        this.startPageLoading();
        this.$apiService.post(`${this.serverUrl}/fleet-upload-processing/form/${this.formId}/uwFleet`,
            { uwFleetImportId: data.import_id, userId: this.userId })
            .then(() => {
              this.getFormDataAndInitialiseUI();
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
      else {
        this.setError("Fleet Upload failed! Please try again.");
      }
    },

    confirmFormAcceptance: function (fleetType) {
      this.fleetBeingAccepted = fleetType;
      this.$refs["acceptFormModal"].show();
    },

    acceptForm: function () {
      this.startPageLoading();
      this.$apiService.post(`${this.serverUrl}/fleet-upload-processing/form/${this.formId}/accept`,
          { userId: this.userId, acceptUwFleet: (this.fleetBeingAccepted === 'UW')})
          .then(() => {
            this.$refs["acceptFormModal"].hide();
            this.stopPageLoading();

            Swal.fire({
              titleText: `Fleet Upload Initiated - Policy ${this.formData.policyNumber}`,
              html: `<p>Please allow a few minutes for the fleet to be fully updated in the CRM.</p>
                   <br/><span class="font-weight-bold">Please close this browser window.</span>`,
              icon: "success",
              width: "40rem",
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
