var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-height":"500px","overflow-y":"scroll"}},[(Array.isArray(this.vehicles) && this.vehicles.length > 0)?_c('table',{staticClass:"table table-bordered"},[_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"2%"}}),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"8%"}},[_vm._v("Rego")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"7%"}},[_vm._v("State")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"15%"}},[_vm._v("Type")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"10%"}},[_vm._v("Make")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"11%"}},[_vm._v("Model")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"8%"}},[_vm._v("Year")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"12%"}},[_vm._v("Market Value")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"12%"}},[_vm._v("Cover Type")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"12%"}},[_vm._v("Location")]),(!_vm.isReadOnly)?_c('th',{staticStyle:{"text-align":"center"},attrs:{"width":"3%"}}):_vm._e(),_vm._l((this.vehicles),function(vehicle,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),(_vm.isReadOnly)?_c('td',[_vm._v(_vm._s(vehicle.K_RegoNo))]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|max:10","name":("vehicle" + index + ".rego")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"size":"sm","type":"text","maxlength":"10","disabled":_vm.isReadOnly},model:{value:(vehicle.K_RegoNo),callback:function ($$v) {_vm.$set(vehicle, "K_RegoNo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.K_RegoNo"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[_vm._v(_vm._s(vehicle.K_State))]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","name":("vehicle" + index + ".state")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"form-control",attrs:{"size":"sm","disabled":_vm.isReadOnly},model:{value:(vehicle.K_State),callback:function ($$v) {_vm.$set(vehicle, "K_State", $$v)},expression:"vehicle.K_State"}},[_c('option',{attrs:{"value":"NSW"}},[_vm._v("NSW")]),_c('option',{attrs:{"value":"VIC"}},[_vm._v("VIC")]),_c('option',{attrs:{"value":"ACT"}},[_vm._v("ACT")]),_c('option',{attrs:{"value":"QLD"}},[_vm._v("QLD")]),_c('option',{attrs:{"value":"WA"}},[_vm._v("WA")]),_c('option',{attrs:{"value":"SA"}},[_vm._v("SA")]),_c('option',{attrs:{"value":"NT"}},[_vm._v("NT")]),_c('option',{attrs:{"value":"TAS"}},[_vm._v("TAS")]),_c('option',{attrs:{"value":"NZ"}},[_vm._v("NZ")])]),_c('span',{staticClass:"text-danger"},[_c('br'),_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[(vehicle.K_VehicleType)?_c('span',[_vm._v(_vm._s(vehicle.K_VehicleType.name))]):_vm._e()]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","name":("vehicle" + index + ".K_VehicleType")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"form-control",attrs:{"size":"sm","options":_vm.vehicleTypes,"text-field":"Name","value-field":"idObject","disabled":_vm.isReadOnly},model:{value:(vehicle.K_VehicleType),callback:function ($$v) {_vm.$set(vehicle, "K_VehicleType", $$v)},expression:"vehicle.K_VehicleType"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[_vm._v(_vm._s(vehicle.K_Make))]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|max:255","name":("vehicle" + index + ".K_Make")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"size":"sm","type":"text","maxlength":"255","disabled":_vm.isReadOnly},model:{value:(vehicle.K_Make),callback:function ($$v) {_vm.$set(vehicle, "K_Make", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.K_Make"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[_vm._v(_vm._s(vehicle.K_Model))]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|max:255","name":("vehicle" + index + ".K_Model")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"size":"sm","type":"text","maxlength":"255","disabled":_vm.isReadOnly},model:{value:(vehicle.K_Model),callback:function ($$v) {_vm.$set(vehicle, "K_Model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.K_Model"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[_vm._v(_vm._s(vehicle.K_Year))]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":{ 'required': true, 'max_value': _vm.currentYear},"name":("vehicle" + index + ".K_Year")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"size":"sm","type":"number","max":_vm.currentYear,"disabled":_vm.isReadOnly},model:{value:(vehicle.K_Year),callback:function ($$v) {_vm.$set(vehicle, "K_Year", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vehicle.K_Year"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[(vehicle.K_MarketValue)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(vehicle.K_MarketValue,"$", ",", 0, ".", "front", true)))]):_vm._e()]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","name":("vehicle" + index + ".K_MarketValue")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-input',{staticClass:"form-control",attrs:{"disabled":_vm.isReadOnly,"allow-negative":false,"distraction-free":false,"precision":0},model:{value:(vehicle.K_MarketValue),callback:function ($$v) {_vm.$set(vehicle, "K_MarketValue", $$v)},expression:"vehicle.K_MarketValue"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[_vm._v(_vm._s(vehicle.K_CoverType))]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","name":("vehicle" + index + ".K_CoverType")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"form-control",attrs:{"size":"sm","disabled":_vm.isReadOnly},model:{value:(vehicle.K_CoverType),callback:function ($$v) {_vm.$set(vehicle, "K_CoverType", $$v)},expression:"vehicle.K_CoverType"}},[_c('option',{attrs:{"value":"Section 1 & 2"}},[_vm._v("Section 1 & 2")]),_c('option',{attrs:{"value":"Section 2 Only"}},[_vm._v("Section 2 Only")])]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isReadOnly)?_c('td',[_vm._v(_vm._s(vehicle.K_BranchCode))]):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","name":("vehicle" + index + ".K_BranchCode")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"form-control",attrs:{"size":"sm","options":_vm.branchCodes,"disabled":_vm.isReadOnly},model:{value:(vehicle.K_BranchCode),callback:function ($$v) {_vm.$set(vehicle, "K_BranchCode", $$v)},expression:"vehicle.K_BranchCode"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(!_vm.isReadOnly)?_c('td',[_c('button',{staticClass:"btn btn-sm btn-outline-danger p-1",attrs:{"title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteVehicle(index)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])]):_vm._e()])}),(!_vm.isReadOnly)?_c('button',{staticClass:"btn btn-outline-primary btn-sm mt-2 mb-2",on:{"click":function($event){$event.preventDefault();return _vm.addVehicle.apply(null, arguments)}}},[_vm._v(" Add Vehicle ")]):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }