<template>
    <div style="max-height: 500px; overflow-y: scroll;">
    <table class="table table-bordered" v-if="Array.isArray(this.vehicles) && this.vehicles.length > 0">
      <th style="text-align: center;" width="2%"></th>
      <th style="text-align: center;" width="8%">Rego</th>
      <th style="text-align: center;" width="7%">State</th>
      <th style="text-align: center;" width="15%">Type</th>
      <th style="text-align: center;" width="10%">Make</th>
      <th style="text-align: center;" width="11%">Model</th>
      <th style="text-align: center;" width="8%">Year</th>
      <th style="text-align: center;" width="12%">Market Value</th>
      <th style="text-align: center;" width="12%">Cover Type</th>
      <th style="text-align: center;" width="12%">Location</th>
      <th v-if="!isReadOnly" style="text-align: center;" width="3%"></th>

      <tr v-for="(vehicle, index) in this.vehicles" :key="index">
        <td>{{ index + 1 }}</td>
        <td v-if="isReadOnly">{{ vehicle.K_RegoNo }}</td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required|max:10" :name="`vehicle${index}.rego`" v-slot="{ errors }">
            <b-form-input size="sm" type="text" maxlength="10" class="form-control" v-model.trim="vehicle.K_RegoNo" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly">{{ vehicle.K_State }}</td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required" :name="`vehicle${index}.state`" v-slot="{ errors }">
            <b-form-select size="sm" class="form-control" v-model="vehicle.K_State" :disabled="isReadOnly">
              <option value="NSW">NSW</option>
              <option value="VIC">VIC</option>
              <option value="ACT">ACT</option>
              <option value="QLD">QLD</option>
              <option value="WA">WA</option>
              <option value="SA">SA</option>
              <option value="NT">NT</option>
              <option value="TAS">TAS</option>
              <option value="NZ">NZ</option>
            </b-form-select>
            <span class="text-danger"><br>{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly"><span v-if="vehicle.K_VehicleType">{{ vehicle.K_VehicleType.name }}</span></td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required" :name="`vehicle${index}.K_VehicleType`" v-slot="{ errors }">
            <b-form-select size="sm" class="form-control" v-model="vehicle.K_VehicleType"
                           :options="vehicleTypes" text-field="Name" value-field="idObject" :disabled="isReadOnly"
            >
            </b-form-select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly">{{ vehicle.K_Make }}</td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required|max:255" :name="`vehicle${index}.K_Make`" v-slot="{ errors }">
            <b-form-input size="sm" type="text" maxlength="255" class="form-control" v-model.trim="vehicle.K_Make" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly">{{ vehicle.K_Model }}</td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required|max:255" :name="`vehicle${index}.K_Model`" v-slot="{ errors }">
            <b-form-input size="sm" type="text" maxlength="255" class="form-control" v-model.trim="vehicle.K_Model" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly">{{ vehicle.K_Year }}</td>
        <td v-if="!isReadOnly">
          <ValidationProvider :rules="{ 'required': true, 'max_value': currentYear}" :name="`vehicle${index}.K_Year`" v-slot="{ errors }">
            <b-form-input size="sm" type="number" :max="currentYear" class="form-control" v-model.trim="vehicle.K_Year" :disabled="isReadOnly"/>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly"><span v-if="vehicle.K_MarketValue">{{ vehicle.K_MarketValue | currency("$", ",", 0, ".", "front", true) }}</span></td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required" :name="`vehicle${index}.K_MarketValue`" v-slot="{ errors }">
            <currency-input class="form-control" v-model="vehicle.K_MarketValue" :disabled="isReadOnly"
                            :allow-negative="false" :distraction-free="false" :precision="0"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly">{{ vehicle.K_CoverType }}</td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required" :name="`vehicle${index}.K_CoverType`" v-slot="{ errors }">
            <b-form-select class="form-control" v-model="vehicle.K_CoverType" size="sm" :disabled="isReadOnly">
              <option value="Section 1 & 2">Section 1 & 2</option>
              <option value="Section 2 Only">Section 2 Only</option>
            </b-form-select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="isReadOnly">{{ vehicle.K_BranchCode }}</td>
        <td v-if="!isReadOnly">
          <ValidationProvider rules="required" :name="`vehicle${index}.K_BranchCode`" v-slot="{ errors }">
            <b-form-select size="sm" class="form-control" v-model="vehicle.K_BranchCode" :options="branchCodes"
                           :disabled="isReadOnly">
            </b-form-select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </td>

        <td v-if="!isReadOnly">
          <button class="btn btn-sm btn-outline-danger p-1" @click.prevent="deleteVehicle(index)" title="Delete">
            <i class="fa fa-trash-alt"></i>
          </button>
        </td>
      </tr>

      <button v-if="!isReadOnly" class="btn btn-outline-primary btn-sm mt-2 mb-2" @click.prevent="addVehicle">
        Add Vehicle
      </button>
    </table>
  </div>
</template>

<style lang="scss">
</style>

<script>

export default {
  name: 'VehiclesList',

  props: {
    vehicles: Array,
    vehicleTypes: Array,
    branchCodes: Array,
    isReadOnly: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {};
  },

  mounted() {},

  computed: {
    currentYear: function () {
      return new Date().getFullYear();
    }
  },

  methods: {
    addVehicle: function () {
      this.vehicles.push({});
    },

    deleteVehicle: function (index) {
      this.vehicles.splice(index, 1);
      // this.calculateBusinessActivityPercentTotal();
    }
  }
};
</script>
